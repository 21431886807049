import { graphql, Link } from "gatsby"
import React from "react"

import Bio from "../components/bio"
import Layout from "../components/layout/index"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

const blogPostTemplateStyle = {
  headerStyle: { textAlign: "center" },
  titleStyle: {
    marginTop: rhythm(1),
    marginBottom: 0,
    fontFamily: `Dosis`,
    fontWeight: 200,
  },
}

const PostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext
  const { headerStyle, titleStyle } = blogPostTemplateStyle

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <header style={headerStyle}>
          <h1 style={titleStyle}>{post.frontmatter.title}</h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            <span role="img" aria-label="calendar">
              📅
            </span>
            {post.frontmatter.date}
            {" | "}
            <span role="img" aria-label="coffee">
              ☕️
            </span>
            {post.frontmatter.readingTime}
          </p>
        </header>
        <nav>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              <Link to="/" rel="prev">
                ← All posts
              </Link>
            </li>
          </ul>
        </nav>

        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer>
          <Bio />
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        readingTime
        tags
      }
    }
  }
`
